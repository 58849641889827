@import 'libs/ui/styles/global.scss';
@tailwind components;
@tailwind utilities;

.subtle {
  @apply text-sm text-dark-400 dark-scoped:text-dark-300;
}

#backdrop {
  @apply fixed top-0 left-0 right-0 bottom-0 w-screen -z-[1] bg-cover bg-no-repeat bg-scroll;
}

.subnav-item {
  @apply font-semibold pb-1 border-b-2 px-3 border-b-dark-500/10 text-dark-500 hover:border-b-dark-300;

  &.active {
    @apply border-b-dark-500 text-black dark:text-white dark:border-b-white;
  }
}
