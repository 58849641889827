@tailwind base;
@import 'node_modules/@aca/ui/tailwind.base.scss';
@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'material';

html {
  @apply w-full h-full m-0 p-0;
}

body {
  @apply min-h-screen min-w-full m-0 p-0 flex flex-col;
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
}

body.aca-modal-open {
  @apply overflow-hidden;
}

.widget {
  @apply p-3 pt-2 md:p-6 md:pt-5 bg-white relative rounded-md;
  border: solid 1px rgba(0, 0, 0, 0.1);

  &:not(.no-shadow) {
    @apply shadow-lg;
  }

  h1 {
    @apply mt-0 mb-3;
  }
}

.acadv-btn {
  @apply font-semibold leading-4 border border-dark-500/10 dark:border-dark-500/50 rounded-full py-2 px-4 flex items-center hover:bg-dark-400/20 whitespace-nowrap;

  &.smaller {
    @apply text-sm py-1 px-3;
  }
  &.active {
    @apply bg-acaBlue text-white;
  }
}

.metric {
  @apply bg-dark-100 rounded-xl p-3 pb-4 px-4;

  .inner {
    @apply flex flex-col gap-3 text-center;
  }
}

body {
  overflow-y: scroll;
}

html::-webkit-scrollbar {
  @apply w-4;
}

html::-webkit-scrollbar-track {
  @apply bg-white rounded-lg shadow-inner;
}

html::-webkit-scrollbar {
  @apply w-[12px] bg-white;
}

html::-webkit-scrollbar-thumb {
  @apply rounded-lg shadow-inner bg-dark-400;
}

.acadv-page {
  @apply mt-0 md:mt-2 text-dark-800;
}

.acadv-link {
  @apply text-primary-600 border-b border-b-dark-300 hover:border-b-primary-600 no-underline transition-colors;
}

.dropdown-subheader {
  @apply px-4 py-1 text-dark-500 dark:text-dark-300 uppercase text-sm;
}

.slim-scrollbar::-webkit-scrollbar,
.ng-dropdown-panel-items::-webkit-scrollbar,
.mat-mdc-autocomplete-panel::-webkit-scrollbar,
.mat-mdc-menu-panel::-webkit-scrollbar {
  width: 7px;
  height: 0;
}

.slim-scrollbar::-webkit-scrollbar-track,
.ng-dropdown-panel-items::-webkit-scrollbar-track,
.mat-mdc-autocomplete-panel::-webkit-scrollbar-track,
.mat-mdc-menu-panel::-webkit-scrollbar-track {
  margin: 1px 0 1px;
}

.slim-scrollbar::-webkit-scrollbar-thumb,
.ng-dropdown-panel-items::-webkit-scrollbar-thumb,
.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb,
.mat-mdc-menu-panel::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 1px;
}

.slim-scrollbar::-webkit-scrollbar-thumb:hover,
.ng-dropdown-panel-items::-webkit-scrollbar-thumb:hover,
.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb:hover,
.mat-mdc-menu-panel::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

.acadv-layout {
  @apply max-w-[1600px] mx-auto pt-0 md:pt-2;
}

.acadv-gap {
  @apply gap-2 lg:gap-4 xl:gap-8;
}
.acadv-inner-gap {
  @apply gap-3 md:gap-6;
}

.acadv-gutter {
  @apply ml-2 mr-2;
}

.acadv-table {
  @apply w-full border border-black/10 border-t-0 border-separate border-spacing-0 relative;
  tbody {
    @apply bg-white;
    tr {
      @apply transition-colors hover:bg-[#feffe6];
    }
  }
  th {
    @apply text-sm font-semibold whitespace-nowrap text-dark-500 px-2 py-1 text-left sticky bg-white z-50 border-b border-t border-black/10 border-r last-of-type:border-r-0;
  }
  td {
    @apply p-2 pr-4 align-top text-left border-b border-black/10 whitespace-nowrap md:whitespace-normal;
  }
}

.acadv-table-wrap {
  @apply p-2 pt-1 md:p-4 md:pt-3;

  @apply px-0 md:px-4;

  @apply w-full overflow-auto max-w-[calc(100vw-16px)] md:max-w-full md:overflow-visible relative;

  background: white;
  position: relative;
  border-radius: 6px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: black;
  @apply shadow-lg;

  .acadv-table {
    @apply border-0;

    thead {
      th {
        @apply border-l-0 border-r-0 border-t-0;
      }
    }

    tbody {
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.acadv-table-inline {
  @apply w-full overflow-auto max-w-[calc(100vw-16px)] md:max-w-full md:overflow-visible relative;

  .acadv-table {
    @apply border-0;

    thead {
      th {
        @apply border-0 border-b-[1px];
      }
    }
    tbody {
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.aca-muted {
  @apply text-dark-500;
}

.form-fields {
  @apply flex flex-wrap gap-4;
}

.label {
  @apply text-sm mb-1 font-semibold;
}

.error {
  @apply text-sm mt-1 font-semibold text-danger-500;
}

.aca-file-dnd {
  &::after {
    pointer-events: none !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: solid 5px transparent;
    content: ' ';
    z-index: 2005;
    transition: border linear 0.2s;
  }
}

.aca-file-dnd-dragging {
  *:not(.aca-file-dnd) {
    pointer-events: none !important;
  }

  &::after {
    border: solid 5px red !important;
  }
}

.req {
  &:after {
    content: '*';
  }
  @apply text-danger-400 font-bold pl-1;
}

.ng-select .ng-select-container {
  border: 1px solid rgb(100 116 139);
}

.is-mockup {
  @apply relative overflow-hidden;
}

.is-mockup::before {
  position: absolute;
  top: -75%;
  left: -75%;
  display: block;
  width: 200%;
  height: 200%;
  pointer-events: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'><text transform='translate(20, 100) rotate(-45)' fill='rgb(80,80,80)' font-size='24' font-family='Arial'>Mockup</text></svg>");
  content: ' ';
  opacity: 0.08;
  line-height: 4em;
  letter-spacing: 2.2px;
  color: #fff;
  z-index: 3;
}

hr {
  @apply border-dark-300;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #fff;
  border: none;
  height: inherit;
  white-space: inherit;
  word-wrap: inherit;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
}

#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  color: #fff;
  background-color: transparent;
}
