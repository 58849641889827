@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat-core();

$acadv-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$indigo-palette),
      accent: mat.define-palette(mat.$green-palette)
    ),
    typography:
      mat-typography-config(
        $font-family: 'inherit'
      )
  )
);

@include mat.core-theme($acadv-theme);
@include mat.menu-theme($acadv-theme);
@include mat.autocomplete-theme($acadv-theme);
@include mat.badge-theme($acadv-theme);
@include mat.stepper-theme($acadv-theme);
@include mat.icon-theme($acadv-theme);

.mat-badge-content {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}
